import "./App.scss";

import { lazy } from "react";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import SuspenseComponent from "./common/Suspense/Suspense";

const About = lazy(() => import("./components/About/About"));
const Banner = lazy(() => import("./components/Banner"));
const News = lazy(() => import("./components/News/News"));
const Gallery = lazy(() => import("./components/Gallery/Gallery"));
const Menu = lazy(() => import("./components/Menu/Menu"));
const Contact = lazy(() => import("./components/Contact/Contact"));

const App = () => {
  return (
    <div className="app">
      <Header />
      <main>
        <SuspenseComponent>
          <About />
          <Banner />
          <News />
          <Gallery />
          <Menu />
          <Contact />
        </SuspenseComponent>
      </main>
      <Footer />
    </div>
  );
};

export default App;
