import './Logo.scss';

import { Link } from 'react-router-dom';
import LogoImage from '../../static/assets/images/logo.svg';

const Logo = () => (
  <figure className='logo'>
    <Link to='/'>
      <img src={LogoImage} alt='Logo malwa' />
    </Link>
  </figure>
);

export default Logo;
