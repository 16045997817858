import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App';
import NoMatch from './components/NoMatch/NoMatch';

import './translations/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppRouter = () => {
  return (
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path='*' element={<NoMatch />} />
          <Route path='/' element={<App />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

root.render(<AppRouter />);
