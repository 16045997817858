import "./MobileNav.scss";
import { navItems } from "../Nav";
import Logo from "../../../static/assets/icons/nav/mobile-nav-logo.webp";
import { useTranslation } from "react-i18next";
import LanguageList from "../../LanguageList/LanguageList";

type MobileNavProps = {
  isOpen: boolean;
  toggleNav: () => void;
  handleOpen: () => void;
};

const MobileNav = ({ isOpen, toggleNav, handleOpen }: MobileNavProps) => {
  const { t } = useTranslation();
  const toggleReservation = () => {
    toggleNav();
    handleOpen();
  };

  return (
    <section id="nav-mobile" className={isOpen ? "open" : "close"}>
      <LanguageList isOpen={isOpen} />
      <ul className="nav-list">
        {navItems.map(({ id, path, title }) => (
          <li key={`${id}-mobile`} className="nav-item">
            <a
              href={id === "reservation-icon" ? "#" : path}
              onClick={
                id === "reservation-icon" ? toggleReservation : toggleNav
              }
            >
              {t(title)}
            </a>
          </li>
        ))}
      </ul>
      <img src={Logo} alt="Dzika malwa mobilne logo menu" />
    </section>
  );
};

export default MobileNav;
