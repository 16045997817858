import { Suspense } from 'react';
import Loading from '../Loading/Loading';

type SuspenseType = {
  children: JSX.Element[];
};

const SuspenseComponent = ({ children }: SuspenseType) => {
  return <Suspense fallback={<Loading />}>{children}</Suspense>;
};

export default SuspenseComponent;
