import './Hamburger.scss';

type HamburgerProps = {
  isOpen: boolean;
  toggleNav: () => void;
};

const Hamburger = ({ isOpen, toggleNav }: HamburgerProps) => {
  return (
    <button
      className={isOpen ? 'hamburger open' : 'hamburger'}
      onClick={toggleNav}
      aria-label='Name'
    >
      <div className='icon'></div>
    </button>
  );
};

export default Hamburger;
