import "./Nav.scss";

import Icon from "../Icon/Icon";

import AboutIcon from "../../static/assets/icons/nav/about.webp";
import ContactIcon from "../../static/assets/icons/nav/contact.webp";
import GalleryIcon from "../../static/assets/icons/nav/gallery.webp";
import MenuIcon from "../../static/assets/icons/nav/menu.webp";
import NewsIcon from "../../static/assets/icons/nav/news.webp";
import ReservationIcon from "../../static/assets/icons/nav/reservation.svg";
import { IconType } from "../Icon/types";
import Hamburger from "./Hamburger/Hamburger";
import MobileNav from "./MobileNav/MobileNav";
import { useState } from "react";
import ReservationModal from "../../components/ReservationModal";

export const navItems: IconType[] = [
  {
    url: AboutIcon,
    alt: "About Icon",
    id: "about-icon",
    title: "header.nav_about",
    path: "#o-nas",
  },
  {
    url: ReservationIcon,
    alt: "Rezerwacja",
    id: "reservation-icon",
    title: "header.nav_reservation",
    path: "#rezerwacja",
    isImportant: true,
  },
  {
    url: MenuIcon,
    alt: "Menu Icon",
    id: "menu-icon",
    title: "header.nav_menu",
    path: "#menu",
  },
  {
    url: GalleryIcon,
    alt: "Gallery Icon",
    id: "gallery-icon",
    title: "header.nav_gallery",
    path: "#galeria",
  },
  {
    url: NewsIcon,
    alt: "News Icon",
    id: "news-icon",
    title: "header.nav_news",
    path: "#aktualnosci",
  },
  {
    url: ContactIcon,
    alt: "Contact Icon",
    id: "contact-icon",
    title: "header.nav_contact",
    path: "#kontakt",
  },
];

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReservationOpen, setIsReservationOpen] = useState(false);

  const toggleNav = () => setIsOpen((prev) => !prev);
  const toggleReservation = () => setIsReservationOpen((prev) => !prev);

  return (
    <nav>
      <ul id="nav-desktop">
        {navItems.map((item) => (
          <li key={item.id}>
            <a
              href={item.path}
              onClick={
                item.id === "reservation-icon" ? toggleReservation : toggleNav
              }
            >
              <Icon {...item} />
            </a>
          </li>
        ))}
      </ul>
      <Hamburger isOpen={isOpen} toggleNav={toggleNav} />
      <MobileNav isOpen={isOpen} toggleNav={toggleNav} handleOpen={toggleReservation}/>
      <ReservationModal
        isOpen={isReservationOpen}
        handleClose={toggleReservation}
      />
    </nav>
  );
};

export default Nav;
