import './ReservationModal.scss';
import closeBtn from '../../static/assets/icons/nav/close-btn.webp';

type ModalProps = {
  isOpen: boolean;
  handleClose: () => void;
}

const ReservationModal = ({isOpen, handleClose}: ModalProps) => {
  return (
    <section className={`reservation-modal ${isOpen && 'open'}`}>
      <div className="box">
        <h2>Rezerwacja Pokoi</h2>
        <ul>
          <li>
            <a href="https://panel.hotres.pl/v4_step1?oid=831&lang=pl&">
              Apartamenty <br/> Stoczek
            </a>
          </li>
          <li>
            <a href="https://panel.hotres.pl/v4_step1?oid=835&lang=pl&">
              Pokoje <br/> Przy Stoczku
            </a>
          </li>
        </ul>
        <img id='close-btn' src={closeBtn} alt="Zamknij modal" onClick={handleClose}/>
      </div>
    </section>
  );
};

export default ReservationModal;
