import './Icon.scss';

import { useTranslation } from 'react-i18next';
import { IconType } from './types';

const Icon = ({ url, alt, id, title, isImportant = false }: IconType) => {
  const { t } = useTranslation();
  return (
    <div className='icon'>
      <figure className={`${isImportant ? 'icon-secondary' : 'icon-primary'}`}>
        <img src={url} alt={alt} id={id} />
      </figure>
      <figcaption>{t(title)}</figcaption>
    </div>
  );
};

export default Icon;
