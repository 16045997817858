import './Footer.scss';
import background from '../../static/assets/images/footer-bgr.webp';

const Footer = () => {
  return (
    <footer>
      <img src={background} alt='Dzika malwa kontakt mural' />
    </footer>
  );
};

export default Footer;
