import './NoMatch.scss';
import Logo from '../../static/assets/icons/nav/mobile-nav-logo.webp';

const NoMatch = () => {
  return (
    <section className='nomatch'>
      <article className='box'>
        <img
          src={Logo}
          alt='Restauracja Regionalna w Białowieży Dzika Malwa - Strona nie istnieje, kod błędu 404'
        />
        <h1>
          Ooops! <br /> 404
        </h1>
      </article>
    </section>
  );
};

export default NoMatch;
